import { useEffect } from 'react'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useChannels } from '~/profile-loader'
import { useAccount } from '~/account'

export const CHANNEL_TYPES = {
  aggregates: 'aggregates',
}

export const FAILURE_CAUSES = {
  error: 'error',
  noData: 'noData',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'channels' implicitly has an 'any' type.
export function shouldItTrack(channels) {
  return channels.length > 0
}

export function useTrackRenderingFail({
  // @ts-expect-error TS(7031) FIXME: Binding element 'chartName' implicitly has an 'any... Remove this comment to see the full error message
  chartName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'channelType' implicitly has an 'a... Remove this comment to see the full error message
  channelType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'failureCause' implicitly has an '... Remove this comment to see the full error message
  failureCause,
}) {
  // @ts-expect-error TS(2339) FIXME: Property 'selectedChannel' does not exist on type ... Remove this comment to see the full error message
  const { channels, selectedChannel } = useChannels()
  const account = useAccount()

  useEffect(() => {
    if (shouldItTrack(channels)) {
      BufferTracker.analyticsChartRenderingFailed({
        product: window.PRODUCT_TRACKING_KEY || 'analyze',
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
        organizationId: account?.currentOrganization?.id,
        clientName: 'web',
        chartName,
        failureCause,
        channel: channelType || selectedChannel?.service,
      })
    }
  }, [])
}
