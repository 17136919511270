import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from '@bufferapp/components'
import { Helper } from '~/shared-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const Label = styled.div`
  margin: 0 0 0.25rem;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'small' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.small &&
    css`
      margin-bottom: 0;
    `}
`

const Value = styled.div``

// @ts-expect-error TS(7031) FIXME: Binding element 'small' implicitly has an 'any' ty... Remove this comment to see the full error message
export default ({ small, label, value }) => (
  <Container>
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    <Label small={small}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: any; }' is not a... Remove this comment to see the full error message */}
      <Helper label={label}>
        <Text size="mini">{label}</Text>
      </Helper>
    </Label>
    <Value>
      <Text color="black" weight="bold" size={small ? 'mini' : 'large'}>
        {value}
      </Text>
    </Value>
  </Container>
)
