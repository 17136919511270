import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import Thumbnail from './Thumbnail'
import Metric from '../Summary/Metric'
import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import { grayLight } from '@bufferapp/ui/style/colors'
import { Text } from '@bufferapp/components'

const Stat = styled.li`
  list-style: none;

  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
`

const Stats = styled.ul`
  background: white;
  padding: 1.25rem 1rem;
  margin: 0;

  > ${Stat}:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const Story = styled.div`
  flex: 1 20%;
  width: 20%;
  box-sizing: border-box;
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width}px;
  padding: 0 0.75rem 0 0;
`

const Filler = styled(Story)`
  background: linear-gradient(180deg, #ededed 0%, rgba(0, 0, 0, 0) 100%);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 3px;
`

const Box = styled.div`
  border-radius: 3px;
  border: 1px solid #ededed;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

export default ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'filler' implicitly has an 'any' t... Remove this comment to see the full error message
  filler,
  // @ts-expect-error TS(7031) FIXME: Binding element 'thumbnail' implicitly has an 'any... Remove this comment to see the full error message
  thumbnail,
  // @ts-expect-error TS(7031) FIXME: Binding element 'date' implicitly has an 'any' typ... Remove this comment to see the full error message
  date,
  // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
  type,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reach' implicitly has an 'any' ty... Remove this comment to see the full error message
  reach,
  // @ts-expect-error TS(7031) FIXME: Binding element 'completionRate' implicitly has an... Remove this comment to see the full error message
  completionRate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'page' implicitly has an 'any' typ... Remove this comment to see the full error message
  page,
  // @ts-expect-error TS(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
  width,
  // @ts-expect-error TS(7031) FIXME: Binding element 'serviceId' implicitly has an 'any... Remove this comment to see the full error message
  serviceId,
}) => {
  return filler ? (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Story width={width}>
      <Filler />
    </Story>
  ) : (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Story width={width}>
      <Box>
        <Thumbnail
          src={thumbnail}
          type={type}
          timestamp={date}
          serviceId={serviceId}
        />
        <Stats>
          <Stat>
            <Metric
              label="Reach"
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; name: string; value: any; s... Remove this comment to see the full error message
              name="reach"
              value={numeral(reach).format('0,0')}
              small
            />
          </Stat>
          <Stat>
            <Metric
              label="Completion Rate"
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; name: string; value: string... Remove this comment to see the full error message
              name="completionRate"
              value={`${numeral(completionRate * 100).format('0.00')}%`}
              small
            />
          </Stat>
          <Stat>
            <ClockIcon color={grayLight} />
            <Text color="outerSpaceLight" weight="bold" size="small">
              {dayjs(date * 1000).format('MMM D[,] hh:mm')}
            </Text>
          </Stat>
        </Stats>
      </Box>
    </Story>
  )
}
