import React from 'react'
import PropTypes from 'prop-types'

import { Button, CheckmarkIcon, Text } from '@bufferapp/components'

import { ProfileBadge } from '~/shared-components'
import style from './style.module.less'

// @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
const DropdownItem = ({ profile, handleClick, selected, disabled }) => (
  <li className={disabled ? style.dropdownListDisabledItem : style.dropdownListItem}>
    <Button noStyle fillContainer onClick={handleClick}>
      <span
        title={profile.username}
        style={{
          alignItems: 'center',
          boxSizing: 'border-box',
          display: 'flex',
          position: 'relative',
          width: '100%',
          padding: '0.5rem 1rem',
        }}
      >
        <ProfileBadge avatarUrl={profile.avatarUrl} service={profile.service} />
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; style: { whiteSpace: "n... Remove this comment to see the full error message
          size="small"
        >
          <Text size="small">{profile.username}</Text>
        </span>
        {selected && (
          <div style={{ marginLeft: 'auto' }}>
            <CheckmarkIcon color={'curiousBlue'} />
          </div>
        )}
      </span>
    </Button>
  </li>
)

DropdownItem.propTypes = {
  profile: PropTypes.shape({
    service: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

DropdownItem.defaultProps = {
  selected: false,
  disabled: false,
}

export default DropdownItem
