import React from 'react'
import PropTypes from 'prop-types'

import {
  PublishIcon,
  ReplyIcon,
  AnalyzeIcon,
  QuestionIcon,
  Divider,
  calculateStyles,
  sidebarBackgroundBlue,
  navbar,
} from '@bufferapp/components'

import PopoverButton from '../PopoverButton'
import BufferLogo from '../BufferLogo'
import PopoverMenu from '../PopoverMenu'
import PopoverMenuItem from '../PopoverMenuItem'
import UserAvatar from '../UserAvatar'

const style = calculateStyles({
  default: {
    background: sidebarBackgroundBlue,
    textAlign: 'center',
    padding: '1rem 0 1rem 0',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '65px',
    zIndex: navbar,
    position: 'sticky',
    bottom: 0,
    top: 0,
    height: 'calc(100vh - 2rem)',
  },
})

const AppSidebar = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'activeProduct' implicitly has an ... Remove this comment to see the full error message
  activeProduct,
  // @ts-expect-error TS(7031) FIXME: Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
  user,
  // @ts-expect-error TS(7031) FIXME: Binding element 'environment' implicitly has an 'a... Remove this comment to see the full error message
  environment,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onMenuItemClick' implicitly has a... Remove this comment to see the full error message
  onMenuItemClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpgradeToProClick' implicitly h... Remove this comment to see the full error message
  onUpgradeToProClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onReturnToClassicClick' implicitl... Remove this comment to see the full error message
  onReturnToClassicClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isProUpgradeUser' implicitly has ... Remove this comment to see the full error message
  isProUpgradeUser,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isAbleToReturnToClassicUser' impl... Remove this comment to see the full error message
  isAbleToReturnToClassicUser,
}) => (
  <nav style={style} aria-label="sidebar" role="menubar">
    <BufferLogo />

    {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
    <PopoverButton
      icon={<PublishIcon />}
      active={activeProduct === 'publish'}
      label="Publish"
      href="https://publish.buffer.com"
    />
    {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
    <PopoverButton
      icon={<ReplyIcon />}
      active={activeProduct === 'reply'}
      label="Reply"
      href="https://reply.buffer.com/"
      newWindow
    />
    {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
    <PopoverButton
      icon={<AnalyzeIcon />}
      active={activeProduct === 'analyze'}
      label="Analyze"
      href="https://analyze.buffer.com/"
      newWindow
    />

    {/* marginTop: auto ensures this section sticks to the bottom (flexbox) */}
    <div style={{ marginTop: 'auto', textAlign: 'center' }}>
      {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
      <PopoverButton
        icon={<QuestionIcon />}
        label="Help and Support"
        popoverPosition="above"
      >
        <PopoverMenu>
          {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
          <PopoverMenuItem href="https://buffer.com/support">
            Help &amp; Support
          </PopoverMenuItem>
          {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
          <PopoverMenuItem href="https://faq.buffer.com">FAQ</PopoverMenuItem>
          {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
          <PopoverMenuItem href="http://status.buffer.com/">
            Status
          </PopoverMenuItem>
          {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
          <PopoverMenuItem href="https://buffer.com/pricing">
            Pricing &amp; Plans
          </PopoverMenuItem>
          {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
          <PopoverMenuItem href="https://buffer.com/feature-request">
            Wishlist
          </PopoverMenuItem>
        </PopoverMenu>
      </PopoverButton>
      {!user.loading && (
        // @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
        <PopoverButton
          icon={<UserAvatar />}
          label="My Account"
          popoverPosition="above"
          large
        >
          <PopoverMenu>
            {/* @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message */}
            <PopoverMenuItem
              href="/preferences/general"
              onClick={() => onMenuItemClick({ menuItemKey: 'preferences' })}
              newWindow
              subtitle="Notifications, time / date, apps&hellip;"
            >
              Preferences
            </PopoverMenuItem>
            {activeProduct === 'publish' && isAbleToReturnToClassicUser && (
              // @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
              <PopoverMenuItem href="#" onClick={onReturnToClassicClick}>
                Switch back to classic Buffer
              </PopoverMenuItem>
            )}
            {activeProduct === 'publish' && isProUpgradeUser && (
              // @ts-expect-error TS(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
              <PopoverMenuItem href="#" onClick={onUpgradeToProClick}>
                ★ Upgrade to Pro
              </PopoverMenuItem>
            )}
          </PopoverMenu>
        </PopoverButton>
      )}
    </div>
  </nav>
)

AppSidebar.propTypes = {
  // translations: PropTypes.shape({}),
  activeProduct: PropTypes.string.isRequired,
  user: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  environment: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  onUpgradeToProClick: PropTypes.func.isRequired,
  onReturnToClassicClick: PropTypes.func.isRequired,
  isProUpgradeUser: PropTypes.bool,
  isAbleToReturnToClassicUser: PropTypes.bool,
}

AppSidebar.defaultProps = {
  activeProduct: 'publish',
  isProUpgradeUser: false,
  isAbleToReturnToClassicUser: false,
  onReturnToClassicClick: () => {},
}

export default AppSidebar
