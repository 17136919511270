import { actions as analyticsActions } from '~/analytics-middleware'
import {
  getNumOfDaysForDateRange,
  getRouteElements,
} from '~/shared-components/utils'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { getOrganizationIdFromStore } from '~/analytics-middleware/middleware'
import { actionTypes, actions } from './reducer'
import generatePNGsFromChart from './lib/generatePNGFromChart'
import downloadAsZip from './lib/downloadAsZip'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { exportToPNG, date, router } = store.getState()
  const routeElements = getRouteElements(router)
  switch (action.type) {
    case actionTypes.EXPORT_TO_PNG_START:
      store.dispatch(
        analyticsActions.trackEvent('pageExported', {
          days: getNumOfDaysForDateRange(date),
          exportType: 'png',
          name: routeElements.name,
          channel: routeElements.channel,
        }),
      )

      return generatePNGsFromChart(exportToPNG.charts, date)
        .then((pngs) => {
          downloadAsZip(action.filename, pngs)
          store.dispatch(actions.endExportToPNG())
          next(action)
        })
        .catch(() => {
          const channel = store.getState().profiles.selectedProfileId
          BufferTracker.analyticsExportFailed({
            exportType: 'images',
            channel,
            clientName: 'analytics',
            reportId: routeElements.name,
            product: window.PRODUCT_TRACKING_KEY || 'analyze',
            organizationId: getOrganizationIdFromStore(store),
          })
        })
    default:
      return next(action)
  }
}
