import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, ArrowLeftIcon, ArrowRightIcon } from '@bufferapp/components'

const ButtonWrapper = styled.span`
  background: white;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.85);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'right' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.right ? '0.5rem' : null};
  left: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'left' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.left ? '0.5rem' : null};
  z-index: 1;

  > * {
    flex: 1;
    height: 100%;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
const PaginationButton = ({ onClick, children, ...props }) => (
  <ButtonWrapper {...props}>
    <Button onClick={onClick} noStyle>
      {children}
    </Button>
  </ButtonWrapper>
)
PaginationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
export const Previous = ({ onClick }) => (
  <PaginationButton onClick={onClick} left>
    <ArrowLeftIcon />
  </PaginationButton>
)

Previous.propTypes = {
  onClick: PropTypes.func.isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
export const Next = ({ onClick }) => (
  <PaginationButton onClick={onClick} right>
    <ArrowRightIcon />
  </PaginationButton>
)

Next.propTypes = {
  onClick: PropTypes.func.isRequired,
}
