import React from 'react'
import styled from 'styled-components'
import Modal from '@bufferapp/ui/Modal'
import Text from '@bufferapp/ui/Text'
import { useAccount } from '~/account'

const Wrapper = styled.section`
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;

  & + * {
    background: none;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'hide' implicitly has an 'any' typ... Remove this comment to see the full error message
function UpgradeToPro({ hide, title, description, cta }) {
  const account = useAccount()

  return (
    <Modal
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; secondaryAction... Remove this comment to see the full error message
      secondaryAction={{
        label: 'Dismiss',
        callback: hide,
      }}
      action={{
        label: 'Upgrade now',
        callback: () => {
          // @ts-expect-error TS(2339) FIXME: Property 'isOneBufferOrganization' does not exist ... Remove this comment to see the full error message
          if (account?.currentOrganization?.isOneBufferOrganization) {
            hide()
            const { MODALS, actions } = window?.appshell || {}
            actions.openModal(MODALS.planSelector, {
              cta,
              isUpgradeIntent: true,
              upgradePathName: 'analyze-upgrade',
            })
          } else {
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location =
              'https://account.buffer.com/new-plans/?cta=analyzeUpgradeNow-migration-cta'
          }
        },
      }}
    >
      <Wrapper>
        <Text type="h2">{title}</Text>
        <Text type="p">{description}</Text>
      </Wrapper>
    </Modal>
  )
}

export default UpgradeToPro
