import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, color } from '@bufferapp/components'
import Capitalized from './Capitalized'

const MetricItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Bar = styled.span`
  border-left: 0.5rem solid
    ${(props) =>
      color[
        // @ts-expect-error TS(2339) FIXME: Property 'network' does not exist on type 'ThemedS... Remove this comment to see the full error message
        props.network
      ]};
  border-radius: 3px;
  margin-right: 0.75rem;
  display: block;
  height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.height}%;
  min-height: 15%;
  position: relative;
  bottom: 0.25rem;
`
const MetricWrapper = styled.section`
  display: flex;
  height: 4.5rem;
  width: 5.4rem;
  align-items: flex-end;

  &:not(:first-child) {
    margin-left: 4.5rem;
  }
`
// @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
const Metric = ({ metric, networkName, height }) => (
  <MetricWrapper>
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    <Bar network={networkName} height={height} />
    <MetricItem>
      <Text color="outerSpace" size="large" weight="bold">
        {metric.percentage}%
      </Text>
      <Text color="shuttleGray" size="mini">
        <Capitalized>{networkName}</Capitalized>
      </Text>
    </MetricItem>
  </MetricWrapper>
)
Metric.propTypes = {
  networkName: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  metric: PropTypes.shape({
    percentage: PropTypes.number,
  }).isRequired,
}

export default Metric
